/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { TraceService } from './common/services/trace.service';
import { UsersProvider } from 'src/providers/users/users';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _usersService: UsersProvider;

  public get usersService(): UsersProvider {
    if (!this._usersService) {
      this._usersService = this.injector.get(UsersProvider);
    }
    return this._usersService;
  }


  Url: string;
  token: string;
  header: any;

  constructor(private http: HttpClient, private injector: Injector,private router: Router, @Inject('DOMAIN') public domain: any[]) {
    const env = this.domain['environment'];
    this.Url = this.domain[env]['endPoints'].Users;

    const headerSettings: {[name: string]: string | string[]; } = {};
    this.header = new HttpHeaders(headerSettings);
  }
  Login(model: any) {
    const env = this.domain['environment'];
    const slug = localStorage.getItem('slug').toLocaleLowerCase();
    this.Url = this.domain[env]['endPoints'].Users + slug;
    const action = (model.mfa) ? '/auth/checkmfa' : '/auth/user/authenticate';
    return this.http.post<any>(this.Url + action, model, { headers: this.header})
      .pipe(map(user => {
        if (!user.mfa) {
          const name = user.firstName + ' ' + user.lastName;
          let role = user.role;
          switch (role) {
            case '1':
              role = 'admin';
              break;
            case '3':
              role = 'Site Admin';
              break;
            case '4':
              role = 'Customer Admin';
              break;
            case '5':
              role = 'Customer Support Admin';
              break;
            case '6':
              role = 'Service Person';
              break;
            default:
              role = 'Site User';
          }
          user.role_type = role;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', user.token);
          localStorage.setItem('id', JSON.stringify(user.id));
          localStorage.setItem('name', name);
          localStorage.setItem('email', JSON.stringify(user.email));
          localStorage.setItem('contact', JSON.stringify(user.contact));
          localStorage.setItem('image_url', user.image);
          // localStorage.setItem('company_id', user.company_id);
          localStorage.setItem('permissions' , user.permissions);
          localStorage.setItem('devices' , user.devices);
          localStorage.setItem('pods' , user.pods);

          localStorage.setItem('role', role);
          localStorage.setItem('isSuperAdmin', user.superAdmin);
        }
        return user;
    }));
  }

  activityLogPut(json){
    this.usersService.activityLogPut(json);
    return this.redirectLoginPage();
  }

  redirectLoginPage(){
    const slug  = localStorage.getItem('slug').toLocaleLowerCase();
    const login =  slug + '/login/' ;
    if (slug) {
      this.router.navigate([login]);
    } else {
      this.router.navigate(['/login']);
    }
    localStorage.clear();
  }

  Logout() {
    let date = new Date().toISOString();
    let activeLogJson = {
      "logout": date,
      "event": "Authentication",
      "application": 5,
      "id": +localStorage.getItem('ActivityLogId')
    };
    this.usersService.activityLogPut(activeLogJson).subscribe(result=>{
      this.redirectLoginPage();
    },(error)=>{
      this.redirectLoginPage();
    });

  }
}
