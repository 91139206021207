/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TraceService } from './trace.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [ TraceService ]
})
export class ServicesModule { }
