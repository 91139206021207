/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

export const domain = {
  'environment': 'prod',
  // Triotos Dev END Points
  'dev': {
    'endPoints':{
      Users: 'https://devapi.iotimp.com/',
      Sites: 'https://devapi.iotimp.com/',
      Vehicles : 'https://yzh48anzf4.execute-api.us-east-2.amazonaws.com/dev/',
      Sensors : 'https://xmw8ume6c6.execute-api.us-west-2.amazonaws.com/dev/',
      Laired: 'https://85y6erzmv6.execute-api.us-east-1.amazonaws.com/dev/',
      Simulation : 'https://7l20bzlne6.execute-api.us-west-2.amazonaws.com/dev/',
    }
  },

  'prod': {
    'endPoints':{
      Users: 'https://api.iotimp.com/',
      Sites: 'https://api.iotimp.com/',
      Vehicles : 'https://yzh48anzf4.execute-api.us-east-2.amazonaws.com/dev/',
      Sensors : 'https://xmw8ume6c6.execute-api.us-west-2.amazonaws.com/dev/',
      Laired: 'https://85y6erzmv6.execute-api.us-east-1.amazonaws.com/dev/',
      Simulation : 'https://7l20bzlne6.execute-api.us-west-2.amazonaws.com/dev/',
    }
  },
  'local': {
    'endPoints':{
      Users: 'http://triotos.local:8000/',
      Sites: 'http://triotos.local:8000/',
      Vehicles : 'https://yzh48anzf4.execute-api.us-east-2.amazonaws.com/dev/',
      Sensors : 'https://xmw8ume6c6.execute-api.us-west-2.amazonaws.com/dev/',
      Laired: 'https://85y6erzmv6.execute-api.us-east-1.amazonaws.com/dev/',
      Simulation : 'https://7l20bzlne6.execute-api.us-west-2.amazonaws.com/dev/',
    }
  }
};
