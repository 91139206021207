/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { BrowserModule, Title } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DlDateTimePickerModule, DlDateTimeDateModule, DlDateTimeInputModule } from 'angular-bootstrap-datetimepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Import Triotos
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';


import { SharedModule } from './common/shared.module';
import { LoginComponent } from './login';


import { AppRoutingModule } from './app-routing.module';
import {ErrorInterceptor } from './_helpers/error.interceptor';

import { MaterialModule } from './material.module';
import { DatePipe } from '@angular/common';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { ForgotComponent } from './forgot';

import { CommonModule } from '@angular/common';


import { domain } from './domain';
import { CarouselModule } from 'ngx-owl-carousel-o';

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
    position: 'left',
    distance: 16
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: false,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 1
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 900,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 900,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 900,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FooterComponent,
    LoginComponent,
    ForgotComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    DragDropModule,
    CarouselModule,
    NotifierModule.withConfig(customNotifierOptions),
    JwtModule.forRoot({
      config: {
        headerName: 'Authorization',
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: [
          'devapi.iotimp.com',
          'api.iotimp.com',
          'triotos.local',
          'triotos.local:8000',
          'jxq5p83kc8.execute-api.us-east-1.amazonaws.com', // Sites
          'o4d555ywkl.execute-api.us-east-1.amazonaws.com', // Alarms
          'rk3wsl7vy0.execute-api.us-east-1.amazonaws.com', // Events
          'tunz7ktkea.execute-api.us-east-1.amazonaws.com', // Polls
          'gwg8b71yf0.execute-api.us-east-1.amazonaws.com', // Users

          'cb1k2am57d.execute-api.us-west-2.amazonaws.com',  // Sites data.iotimp.com
          'w08644cz8b.execute-api.us-west-2.amazonaws.com', // Polls data.iotimp.com
          'oqnxvodot0.execute-api.us-west-2.amazonaws.com', // Users data.iotimp.com
          'i6a4l7w172.execute-api.us-west-2.amazonaws.com', // Events data.iotimo.com
          'rlkrm2k11a.execute-api.us-west-2.amazonaws.com', // Alarms data.iotimo.com
          'fh3tz2kde8.execute-api.us-west-2.amazonaws.com', // Analytics IoTprovision.iotimp.com
          '7l20bzlne6.execute-api.us-west-2.amazonaws.com', // Simulation data.iotimp.com

          // Dev
          'pllvzu3bph.execute-api.us-west-2.amazonaws.com', // Users Dev
          'gwcbz8fo97.execute-api.us-west-2.amazonaws.com', // Sites Dev
          'm6z693x341.execute-api.us-west-2.amazonaws.com', // Polls Dev
          'k7udp9feda.execute-api.us-west-2.amazonaws.com', // Events Dev
          '5ssh4ssx5j.execute-api.us-west-2.amazonaws.com', // Alarms Dev
          'dkymlbu1e9.execute-api.us-west-2.amazonaws.com', // Analytics Dev
        ],
        blacklistedRoutes: ['example.com/examplebadroute/']
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MaterialModule,
    DlDateTimeDateModule,
    DlDateTimeInputModule,
    DlDateTimePickerModule,


    CommonModule,

    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),

    AppRoutingModule,
  ],
  providers: [[DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'DOMAIN', useValue: domain }],
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


