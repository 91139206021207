/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersProvider {

  public slug = localStorage.getItem('slug');
  private _apiUrl: string;

  constructor(private http: HttpClient, @Inject('DOMAIN') public domain: any[]) {
    this._apiUrl = this.domain[this.domain['environment']]['endPoints'].Users+ this.slug;
  }

  add(data) {
    return this.http.post(this._apiUrl + 'register', data);
  }

  update(id, data) {
    return this.http.put(this._apiUrl + 'Update/' + id, data);
  }

  uploadImage(data) {
    return this.http.post(this._apiUrl + 'ImageUpload/', data);
  }

  changePassword(id, data) {
    return this.http.put(this._apiUrl + 'UpdatePassword/' + id, data);
  }

  delete(id) {
    return this.http.delete(this._apiUrl + 'Delete/' + id);
  }

  activityLogPut(json){
    const id = localStorage.getItem('ActivityLogId');
    return this.http.put(this._apiUrl + '/api/logs/activity_log/'+id+'/', json);
  }

  activityLogPost(json){
    return this.http.post(this._apiUrl + '/api/logs/activity_log/', json);
  }

  list() {
    return this.http.get(this._apiUrl + 'GetAll/');
  }

  listRoles() {
    return this.http.get(this._apiUrl + 'GetAllRoles/');
  }

  get(id) {
    return this.http.get(this._apiUrl + 'GetById/' + id);
  }

  GetUnassignedUsersOfSite(id) {
    return this.http.get(this._apiUrl + 'GetUnassignedUsersOfSite/' + id);
  }

  GetAssignedUsersOfSite(id) {
    return this.http.get(this._apiUrl + 'GetAssignedUsersOfSite/' + id);
  }

  changeForgotPass(data) {
    return this.http.post(this._apiUrl + '/auth/user/changepassword', data);
  }

  requestForgotPass(email) {
    return this.http.post(this._apiUrl + '/auth/user/forgotpassword', { email: email});
  }
  getCustomerBySlug() {
    return this.http.get(this._apiUrl + '/auth/user/authenticate');
  }

  getCompanyLocation(): Observable<any> {
    return this.http.get(this._apiUrl + '/api/company/companyuserdetail');
  }

  GetPodByCustomer(): Observable<any> {
    return this.http.get(this._apiUrl + '/api/pod/companypod');
  }
}
