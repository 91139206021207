/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  slug: string;

  public constructor(private titleService: Title,
    @Inject('DOMAIN') public domain: any[] ) {
    this.slug = localStorage.getItem('slug');
    if (this.slug) {
      this.getTitle();
    }
  }

  getTitle() {
    const title = this.slug.charAt(0).toUpperCase() + this.slug.slice(1);
    if (this.slug.toLowerCase() === 'triotos') {
      this.titleService.setTitle(title + ' App');
    } else {
      this.titleService.setTitle(title);
    }
  }
}
