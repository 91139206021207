/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { Component, OnInit } from '@angular/core';
import { TraceService } from '../common/services/trace.service';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { LoginService } from '../login.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isLoggedIn: boolean;
  slug: string;
  name: string;
  logo: string;
  company_name: string;
  imageUrl: string;
  host:string;
  isDev:any;
  sampleImage = "";
  domain:any ={ 'dev': 'devportal.iotimp.com', 'stage': 'portal.iotimp.com'};
  user: any;
  is_data_processing: any;

  constructor(public traceService: TraceService, public loginService:LoginService, private router: Router, public route: ActivatedRoute) {
    this.host= window.location.hostname;
    if(this.host === this.domain['dev']){
      this.isDev = true;
    }else{
      this.isDev = false;
    }
  }

  ngOnInit() {
    this.isLoggedIn = (localStorage.getItem('token') != null);
    this.is_data_processing = localStorage.getItem('is_data_processing');
    this.is_data_processing = (this.is_data_processing ==  'true')?true: false;
    this.logo = 'assets/images/triotos-logo.png';
    this.imageUrl = 'assets/images/samplepic.png';
    this.slug = (localStorage.getItem('slug'))? localStorage.getItem('slug').toLocaleLowerCase(): "" ;

    this.company_name = localStorage.getItem('company_name');
    this.name = localStorage.getItem('name');

    // Check if same user is logged in
    const urlSlug = this.route.snapshot.params.slug;
    if (this.slug !== 'triotos') {
      this.traceService.checkIfSameUser(urlSlug);
      this.logo = localStorage.getItem('logo');
    } else if (this.slug && urlSlug && this.slug  !== urlSlug) {
      this.traceService.checkIfSameUser(urlSlug);
      this.checkImage();
    }
    const image_url = localStorage.getItem('image_url');
    if (image_url !== '') {
      this.imageUrl = localStorage.getItem('image_url')
    } else {
      this.imageUrl = 'assets/images/samplepic.png';
    }

  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
      1200: {
        items: 4,
      }
    },
    nav: true
  }




  checkImage(){
    this.traceService.getCompanyInfo().subscribe(data => {
      this.user = data['user'][0];
      if(this.user.image){
        localStorage.setItem('image_url', this.user.image);
        this.imageUrl = localStorage.getItem('image_url');
      }
    });
  }

  login() {
    const path = '/login';
    this.router.navigate([path]);
  }

  logout() {
    this.loginService.Logout();
  }
}
