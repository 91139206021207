/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { TraceService } from '../common/services/trace.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RxwebValidators, model } from '@rxweb/reactive-form-validators';
import { CustomValidators } from '../custom-validators';

declare var $: any;

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, AfterViewInit {

 /// model: any = {};

  errorMessage: any;
  token: any;
  email: any;
  loading: any;
  forgotPasswordForm: FormGroup;
  submitted1 = false;
  host: string;
  logo: string;
  slogan: string;

  @ViewChild('password') private nameField: ElementRef;
  location: any;
  customerData: any;
  image_Url: any;
  imageUrl: string;
  loginUrl: any;
  company_id: number;
  isPassword: any;
  isConfirmPassword: boolean;

  constructor(private router: Router
    , @Inject('DOMAIN') public domain: any[]
    , public traceService: TraceService, private formBuilder: FormBuilder,
    private route: ActivatedRoute) {
      this.location = this.route.snapshot.params.slug;
      localStorage.setItem('slug', this.location);
      this.imageUrl = './assets/logo/Blocks.gif'; }

  ngOnInit() {
    console.log(this.location);


    this.host = window.location.hostname;

    if ((this.host  !== 'triotos' && this.host  !== 'Triotos') && this.host ) {
      this.getCustomer();
    } else {
      localStorage.removeItem('logo');
      const slug = (this.host) ? this.host : 'triotos';
      localStorage.setItem('slug', slug);
      this.imageUrl = './assets/logo/triotos-logo.png';
    }
    this.formValidator();
  }

  public ngAfterViewInit(): void {
    this.nameField.nativeElement.focus();
  }


  formValidator() {
    this.forgotPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)])
      ],
      confirmPassword : [null]
    });
  }
  get g() { return this.forgotPasswordForm.controls; }

  // delay = ms => new Promise(res => setTimeout(res, ms));
  getCustomer() {
    this.traceService.getCustomerBySlug().subscribe(data => {
      const img:any = data;
      this.imageUrl = img.image;
      if(this.imageUrl){
        localStorage.setItem('logo', this.imageUrl);
      }else if(!this.imageUrl){
        this.imageUrl = './assets/logo/triotos-logo.png';
      }
    });
  }

  change() {
    this.submitted1 = true;
    if (this.forgotPasswordForm.invalid) {

      return false;
    }
    const password = this.forgotPasswordForm.controls['password'].value;
    const confirm_password = this.forgotPasswordForm.controls['confirmPassword'].value;
    if (confirm_password === '') {
      this.errorMessage = 'Please Enter Confirm Password';
      return;
    } else if (password !== confirm_password) {
      this.errorMessage = 'Confirm Password dose not matched.';
      return;
    } else {
      // get the email and token from URL
      const url_string = window.location.href;
      const url = new URL(url_string);
      const e = url.searchParams.get('email');
      const t = url.searchParams.get('token');
      this.token = t;
      this.email = e;

      this.loading = true;
      this.errorMessage = '';
      this.g.recovery_token = this.token;
      this.g.Email = this.email;
      delete this.g.confirm_password;

    this.traceService.changeForgetPassword({password: password, token: this.token , email: this.email }).subscribe( data => {
        this.loading = false;
        $('#oKModal').modal('show');
        // $('#oKModal').modal('hide');
        // this.router.navigate(['/login']);
      },
      error  => {

        this.loading = false;
        this.errorMessage = 'Something wrong';
        // this.router.navigate(['/login']);
      });

    }
  }

  login() {
    $('#oKModal').modal('hide');
    let path = '',
    slug = localStorage.getItem('slug');
    path =  '/' + slug + '/login';
    this.router.navigate([path]);
  }

  togglePassword(){
    this.isPassword = !this.isPassword;
  }
  toggleConfirmPassword(){
    this.isConfirmPassword = !this.isConfirmPassword;
  }

}
