/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import {Injectable, Injector} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UsersProvider } from '../../../providers/users/users';
import { LoginService } from '../../login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TraceService {
  private slug: string;

  constructor(private http: HttpClient, private injector: Injector, private router: Router, public route: ActivatedRoute) {
    this.slug = localStorage.getItem('slug');
  }

  private _usersService: UsersProvider;
  private _loginService: LoginService;

  /**
   *  Login
   */
  public get loginService(): LoginService {
    if (!this._loginService) {
      this._loginService = this.injector.get(LoginService);
    }
    return this._loginService;
  }


  /**
   *  Users
   */
  public get usersService(): UsersProvider {
    if (!this._usersService) {
      this._usersService = this.injector.get(UsersProvider);
    }
    return this._usersService;
  }

  getUserImage(url) {
    return this.http.get(url, {responseType: 'text'});
  }

  getCompanyInfo() {
    return this.usersService.getCompanyLocation();
  }

  uploadUserImage(data) {
    return this.usersService.uploadImage(data);
  }

  activityLogPut(json){
    return this.usersService.activityLogPut(json);
  }

  activityLogPost(json){
    return this.usersService.activityLogPost(json);
  }

  updateUser(id, data) {
    return this.usersService.update(id, data);
  }

  changePassword(id, data) {
    return this.usersService.changePassword(id, data);
  }

  getCustomerBySlug() {
    return this.usersService.getCustomerBySlug();
  }

  changeForgetPassword(data) {
    return this.usersService.changeForgotPass(data);
  }

  requestForgetPassword(email) {
    return this.usersService.requestForgotPass(email);
  }

  getImage(url) {
    return this.http.get(url, {responseType: 'text'});
  }

  /**
   * Helper functions
   */
  checkIfSameUser(urlSlug) {
    this.slug = localStorage.getItem('slug');
    if (urlSlug !== this.slug) {
      const path = '/' + this.slug;
      this.router.navigate([path]);
    }
  }
}
