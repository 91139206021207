/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../login.service';
import { TraceService } from '../common/services/trace.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @ViewChild('username') private nameField: ElementRef;
  model: any = {};
  loading: any;
  loadingMail: any;
  email: any;
  emailFound: any;
  MFA = false;
  interval: any;
  resendTimeout: any;
  resendEmail: boolean;
  logo: any;
  slogan: any;
  emailForm:  FormGroup;
  submitted: boolean;

  errorMessage: string;
  host: string;

  location: any;
  customerData: any;
  image_Url: any;
  imageUrl: string;
  loginUrl: any;
  slug: string;
  isPassword : boolean;
  // tslint:disable-next-line: no-shadowed-variable
  constructor(private router: Router,  @Inject('DOMAIN') public domain: any[],
  // tslint:disable-next-line: no-shadowed-variable
  private LoginService: LoginService, public route: ActivatedRoute, private formBuilder: FormBuilder,
  public traceService: TraceService) {
    localStorage.setItem('slug', this.route.snapshot.params.id);
    this.imageUrl = './assets/images/loading.gif';
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.host = window.location.hostname;
    this.location = this.route.snapshot.params.id;
    this.slug = localStorage.getItem('slug');
    if (localStorage.getItem('token') != null) {
      let path = '';
      path = this.slug + '/';
      this.router.navigate([path]);
    }
    if ((this.location  !== 'triotos' && this.location  !== 'Triotos') && this.location ) {
      this.getCustomer();
    } else {
      localStorage.removeItem('logo');
      const slug = (this.location) ? this.location : 'triotos';
      localStorage.setItem('slug', slug);
      this.imageUrl = './assets/logo/triotos-logo.png';
    }
    this.validateForm();
  }

  togglePassword(){
    this.isPassword = !this.isPassword;
  }

  validateForm() {
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }
  get g() { return this.emailForm.controls; }

  getCustomer() {
    this.traceService.getCustomerBySlug().subscribe(data => {
      this.customerData = data;
      localStorage.setItem('is_data_processing', this.customerData.is_data_processing);
      if(this.customerData['image']){
        this.imageUrl = this.customerData.image;
        localStorage.setItem('logo', this.imageUrl);
      }else{
        this.imageUrl = './assets/logo/triotos-logo.png';
      }
    });
  }

  public ngAfterViewInit(): void {
    this.nameField.nativeElement.focus();
  }


  login() {
    this.loading = true;
    this.model.username = this.model.username;
    if ((this.location !== 'triotos' && this.location !== 'Triotos') && this.location) {
      if (this.customerData) {
        localStorage.setItem('slug' , this.location.toLowerCase());
      } else {
        this.loading = false;
        this.errorMessage = 'Company doesn\'t exist!';
        return false;
      }
    } else {
      localStorage.setItem('slug' , 'triotos');
    }
    const json={
      username: this.model.username,
      password: this.model.password
    };
    this.LoginService.Login(json).subscribe(
      data => {
        if (data.token != null) {
          this.loading = false;
          this.activityLogPost();
          let path = '';
          this.slug = localStorage.getItem('slug');
          path =  '/' + this.slug + '/';
          this.router.navigate([path]);
        } else if (data.mfa) {
          this.loading = false;
          this.MFA = true;

          this.countdown();

          this.errorMessage = ''; // data.Message;
        } else if (data.role_type === 'Site User' && data.isProvision) {
          this.loading = false;
          this.errorMessage = 'You are not authorized to perform this action.';
        } else {
          this.loading = false;
          this.errorMessage = 'Login Failed!'; // data.Message;
        }
      },
      error => {
        this.loading = false;
        this.errorMessage = 'Login Failed!'; // error.message;
      });
  }

  mfaCode() {
    this.loading = true;
    this.model.mfa = this.MFA;
    const json = {
      username: this.model.username,
      password: this.model.password,
      code:this.model.code
    }
    this.LoginService.Login(json).subscribe(
      data => {
        if (data.token != null) {
          this.loading = false;
          let path = '';
          this.slug = localStorage.getItem('slug');
          path =  '/' + this.slug + '/';
          this.router.navigate([path]);
          this.activityLogPost();
        } else {
          this.loading = false;
          this.errorMessage = 'Login Failed!'; // data.Message;
        }
      },
      error => {

        this.countdown();
        this.loading = false;
        this.errorMessage = 'Login Failed!!!!'; // error.message;
      });
  }

  countdown() {
    const self = this;
    this.resendTimeout = '1:59';
    this.resendEmail = false;
    clearInterval(this.interval);
    this.interval = setInterval( function() {
      let timer = self.resendTimeout;
      timer = timer.split(':');
      let minutes, seconds;

      minutes = Number(timer[0]);
      seconds = Number(timer[1]);
      if (minutes < 0) {
        return;
      } else if (seconds < 0 && Number(minutes) !== 0) {
        minutes -= 1;
        seconds = 59;
      } else if (seconds < 10 && length['seconds'] !== 2) {
        seconds = '0' + seconds;
      }

      seconds -= 1;

      self.resendTimeout = minutes + ':' + seconds;
      if (Number(minutes) === 0 && Number(seconds) === 0) {
        self.resendEmail = true;
        clearInterval(self.interval);
      }
    }, 1000);
  }

  sendEmail() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return false;
    }
    this.loadingMail = true;
    this.email = $('input[name=email]').val();
    this.traceService.requestForgetPassword(this.email).subscribe(
    data => {
      this.loadingMail = false;
      if (data['message'] === 'Mail send successfully !') {
        this.emailFound = true;
      } else {
        this.emailFound = false;
      }
    },
    error => {
      this.loadingMail = false;
      this.emailFound = false;
    });
  }

  activityLogPost(){
    let date = new Date().toISOString();

    let activeLogJson = {
      "event": "Authentication",
      "action": "Login",
      "application": 5,
      "user": localStorage.getItem('id')
    };
    // localStorage.setItem('activeLog', JSON.stringify(activeLogJson));
    this.traceService.activityLogPost(activeLogJson).subscribe(result=>{
      localStorage.setItem('ActivityLogId', result['id']);
    },(error)=>{
      console.log(error);
    })

  }

  openModal() {
    this.emailFound = null;
    this.email = null;
      $('#emailForm')[0].reset();
      $('email').val('');
    $('#getEmailModal').modal('show');
  }
 }
