/******************************************************
 * Copyright © Triotos Inc..  All rights reserved.
 *****************************************************/

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login';
import { ForgotComponent } from './forgot';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: {title: 'Login Page'}},
  { path: ':id/login', component: LoginComponent, data: {title: 'Login Page'}},
  { path: ':slug', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: '', component: DashboardComponent},
  { path: ':slug/passwordRecovery', component: ForgotComponent},
  { path: ':slug/changepassword', component: ForgotComponent},
  { path: '**', redirectTo: '' }
];

@NgModule({
imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
exports: [RouterModule]
})
export class AppRoutingModule { }
